<template>
  <div class="platformServices">
    <div class="title">COMPAX DMS</div>
    <div class="text">
      Developed an integrated end-to-cloud commercial device management system
      tailored for partners, offering professional, efficient, simple, secure,
      and controllable device and content management capabilities.
    </div>
    <a href="https://dms.compax.cc/#/" target="_blank" class="button">Get Started</a>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.platformServices {
  padding-top: 23px;
  height: 476px;
  background: url('../../../assets/PlatformServices/无标题2.png') no-repeat
    center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    color: rgba(255, 255, 255, 1);
    font-size: 26px;
    font-weight: 700;
    line-height: 37.65px;
    margin-bottom: 12.49px;
  }
  .text {
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    font-size: 10px;
    line-height: 14.48px;
    width: 249px;
    text-align: justify;
  }
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    margin-top: 10px;
    border-radius: 20px;
    background: rgba(0, 92, 255, 1);
    width: 106px;
    height: 28px;
  }
}
</style>
